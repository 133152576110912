import React, { useEffect, useRef, useState } from 'react'
import WishlistPage from 'src/views/wishlist'
import { graphql } from 'gatsby'
import { useSession } from 'src/sdk/session'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useCampaignContext } from 'src/contexts/campaign-context'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'

function Page() {
  const { person } = useSession()
  const { sendPageViewEvent } = usePageViewEvent('Wishlist', person)
  const [didMount, setDidMount] = useState(false)
  const { setCampaignPage } = useCampaignContext()

  setCampaignPage(false)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  return (
    <>
      <GatsbySeoCustom
        title="Wishlist"
        description="Wishlist Page"
        language="pt-BR"
        noindex
        nofollow
      />
      <WishlistPage />
    </>
  )
}

export const query = graphql`
  query WishlistPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
